import leaderboard from '@/assets/icons/leader_board.svg'
import leaderboardBoost from '@/assets/icons/leader_board_boost.svg'
import leaderboardGreen from '@/assets/icons/leader_board_active.svg'
import logo from '@/assets/icons/logo_big_size.png'
import cart from '@/assets/icons/cart_gray.svg'
import cartBoost from '@/assets/icons/cart_boost.svg'
import cartGreen from '@/assets/icons/cart_active.svg'
import people from '@/assets/icons/people.svg'
import peopleBoost from '@/assets/icons/people_boost.svg'
import peopleGreen from '@/assets/icons/people_active.svg'
import gift from '@/assets/icons/gift.svg'
import giftBoost from '@/assets/icons/gift_boost.svg'
import giftGreen from '@/assets/icons/gift_boost_active.svg'
import wallet from '@/assets/icons/wallet.svg'
import walletBoost from '@/assets/icons/wallet_boost.svg'
import walletGreen from '@/assets/icons/wallet_active.svg'
import walletWhite from '@/assets/icons/wallet_white.svg'
import guglobal from '@/assets/icons/icon-guglobal.svg'
import guglobalActive from '@/assets/icons/icon-guglobal_active.svg'
import guglobalBoost from '@/assets/icons/icon-guglobal_boost.svg'

import closeGray from '@/assets/icons/icon_close.svg'
import closeWhite from '@/assets/icons/close_white.svg'
import loadingWhite from '@/assets/images/loading.png'
import tickGreen1 from '@/assets/icons/tick_green_1.svg'
import lockWhite1 from '@/assets/icons/icon-lock.svg'
import copyWhite from '@/assets/icons/icon-copy-invite.svg'
import quesMark from '@/assets/icons/circle_question_mark.svg'

import tokenGum from '@/assets/icons/icon_cart_circle.svg'
import coinT from '@/assets/icons/coint_t_green.svg'

import arrUnderlineDownWhite from '@/assets/icons/underlined_tick.svg'
import arrUnderlineDownGreen from '@/assets/icons/underlined-arr-green.svg'
import arrUnderlineDownRed from '@/assets/icons/underlined-arr-red.svg'
import doubleArrWhite from '@/assets/icons/double-arr-white.svg'
import arrDownGreen from '@/assets/icons/icon-up-invite.svg'

import clockArrGreen from '@/assets/icons/icon-arr-clock-green.svg'
import calendar from '@/assets/icons/icon-calendar.svg'

import network from '@/assets/icons/icon-network.png'
import dApp from '@/assets/icons/icon-d-app.png'
import eCom from '@/assets/icons/icon-e-com.png'
import p2p from '@/assets/icons/icon-p2p.png'
import guDex from '@/assets/icons/icon-gudex.png'
import guCard from '@/assets/icons/icon-gucard.png'
import ecosystem from '@/assets/icons/icon-ecosystem.svg'

import web from '@/assets/icons/web.svg'
import x from '@/assets/icons/x-white.svg'
import telegramOutline from '@/assets/icons/telegram-outline.svg'
import telegramSolid from '@/assets/icons/telegram-solid.svg'

import deposit from '@/assets/icons/arr-deposit.svg'
import withdraw from '@/assets/icons/arr-deposit.svg'
import giftCircleBlue from '@/assets/icons/gift-circle-blue.svg'
import gudex from '@/assets/icons/gudex-circle-blue.svg'

export const iconsBottomBar = {
  people: people,
  peopleActive: peopleGreen,
  peopleBoost: peopleBoost,
  leaderboard: leaderboard,
  leaderboardActive: leaderboardGreen,
  leaderboardBoost: leaderboardBoost,
  logo: logo,
  gift: gift,
  giftBoost: giftBoost,
  giftActive: giftGreen,
  cart: cart,
  cartBoost: cartBoost,
  cartActive: cartGreen,
  wallet: wallet,
  walletActive: walletGreen,
  walletBoost: walletBoost,
  guglobal: guglobal,
  guglobalActive: guglobalActive,
  guglobalBoost: guglobalBoost
}
export const iconsCommon = {
  closeWhite: closeWhite,
  closeGray: closeGray,
  loadingWhite: loadingWhite,
  tickGreen1: tickGreen1,
  lockWhite1: lockWhite1,
  copyWhite: copyWhite,
  quesMarkBlue: quesMark
}

export const iconsToken = {
  gum: tokenGum,
  usdt: coinT
}

export const iconsArrow = {
  underlineWhite: arrUnderlineDownWhite,
  underlineDownGreen: arrUnderlineDownGreen,
  underlineDownRed: arrUnderlineDownRed,
  doubleArrWhite: doubleArrWhite,
  downGreen: arrDownGreen
}

export const iconsOthers = {
  clockArrGreen : clockArrGreen,
  walletWhite: walletWhite,
  calendar: calendar
}

export const iconsExplore = {
  network: network,
  dApp: dApp,
  eCom: eCom,
  p2p: p2p,
  guDex: guDex,
  guCard: guCard,
  ecosystem: ecosystem
}

export const iconsSocial = {
  web: web,
  x: x,
  telegramOutline: telegramOutline,
  telegramSolid: telegramSolid
}

export const iconsMenuWallet = {
  deposit: deposit,
  withdraw: withdraw,
  gift: giftCircleBlue,
  gudex: gudex
}
